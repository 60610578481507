<template>
  <div  :class="[onlinestate=='ipad'?'ipad_class':onlinestate == 'phone'?'phone_class':'pc_class']">
    <div id='wrap_hand' class='wrap_hand wrap_hand_animation' style="display: flex;align-items: center;">
        <div class='heard_left myun_down' @click="backClick()" style="cursor: pointer;"><img @dragstart.prevent src="@/image/onhand/logo.png" alt=""></div>
        <div class='heard_right myun_down' style="margin-left: auto;" >
          <section class="mod model-1">
						<div class="menu" @click="menuPage()" :class="menuFlag ?'menu menu_hover':'menu'">
							<div class="bar"></div>
							<div class="bar"></div>
							<div class="bar"> </div>
						</div>
					</section>
        </div>
        <!-- @click="menuPage()" -->
        
    </div>
    <img :src="params.banner.banner_url_web" alt="" style="width:100%;min-height:470px;" v-if="onlinestate == 'pc'">
    <img :src="params.banner.banner_url_app" alt="" style="width:100%;" :style="{minHeight:onlinestate == 'ipad'?'324px':'158px'}" v-else>
    <div class="xieyi_div">
      <!-- <div class="look_g"><div></div>Privacy Data Policy
      </div> -->

      <div v-for='(v,i) in privacyPolicyList' :key="i">
        <div v-if="v">
          <div class='look_g' v-if='v.page_text'>
            <div></div>{{v.page_text}}
          </div>
          <pre class="fonts look_l"  v-for='(v1,i1) in v.contents' :key="i1" style="color: #333;margin: 0.5rem 0px ;display: flex;">
            <span style="width: 100%;">{{v1.page_contect}}</span>
          </pre>
        </div>
      </div>
      <pre class="fonts look_l" style="color: #333;font-weight: 600;margin: 0.3rem 0px ;display: flex;">
        <span style="width: 97%;">{{params.date.page_text}}</span>
      </pre>
    </div>
    <div style="height: 2rem;"></div>
    <!-- <div class="mc_cla" v-if="pageFlag"></div> -->
    <cookies></cookies>
    <div class="menu_div" v-if="menuFlag" @click="Move()">
			<div style="text-align: center;color: #ffffff;" class="menu_class">
				<div class="back_hover" style="cursor: pointer;" @click="skipClick(1)">主页</div>
				<div class="back_hover" style="margin-top:10px;cursor: pointer;" @click="skipClick(2)">关于我们</div>
				<div class="back_hover" style="margin-top:10px;cursor: pointer;" @click="skipClick(3)">服务范畴</div>
				<div class="back_hover" style="margin-top:10px;cursor: pointer;" @click="skipClick(4)">作品案例</div>
				<div class="back_hover" style="margin-top:10px;cursor: pointer;" @click="skipClick(5)">就业机会</div>
				<div class="back_hover" style="margin-top:10px;cursor: pointer;" @click="skipClick(6)">联络我们</div>
			</div>
		</div>
  </div>
</template>

<script>
  import cookies from '../cookies/cookie.vue'
  export default {
    components: {
      cookies,
    },
      data() {
          return{
            menuFlag:false,
            pageFlag:true,
            onlinestate:sessionStorage.onlinestate,
            params:{},
            privacyPolicyList:[
                {
                    "bigtitle": "私隐政策",
                    "titlenum": null,
                    "textnum": null,
                    "text3": null,
                    "text2": null,
                    "text": null,
                    "title": null,
                    "text3num": null,
                    "text2num": null
                },
                {
                    "bigtitle": null,
                    "titlenum": null,
                    "textnum": null,
                    "text3": null,
                    "text2": null,
                    "text": null,
                    "title": "大连铧亚软件科技开发有限公司（「本公司」）秉持维护个人私隐的政策，确保我们的客户（包括网站用户）所提供的所有信息均会保密处理。任何人士在浏览我们的网站时，我们不会收集任何能够辨别浏览者身份的信息（「个人资料」）。若本公司必须收集个人资料，例如在用户提出查询的过程中需要收集个人资料时，我们将通过网站的「个人信息收集声明」通知相关人士收集其个人信息的目的，以及日后可能接收该等信息的任何界别的人士，无论有关的披露属强制性或自愿性。网站设定了Cookie，目的仅在方便客户日后登陆同一网站，不作任何其他用途。",
                    "text3num": null,
                    "text2num": null
                },
                {
                    "bigtitle": null,
                    "titlenum": null,
                    "textnum": null,
                    "text3": null,
                    "text2": null,
                    "text": null,
                    "title": "任何经收集的个人资料将在本公司的内部系统处理及储存。总括而言，只有获授权、经适当培训的职员有权限接触这等个人资料。本公司使用个人资料时，会按相关人士获知会或已允许的用途。同样地，本公司绝不会在未取得相关人士的知情同意下，向其发送直销资料推介本公司的产品和服务，我们亦会为该等人士提供不接收数据的选项，供选择在任何时间均不从本公司接收这等数据。",
                    "text3num": null,
                    "text2num": null
                },
                {
                    "bigtitle": null,
                    "titlenum": null,
                    "textnum": null,
                    "text3": null,
                    "text2": null,
                    "text": null,
                    "title": "所有曾向本公司提供个人资料的人士，有权要求查阅或修改其个人资料。如欲行使这等权利，请发送电邮至 info@qmisit.com.hk 。\n如任何人士对本公司的私隐政策或有任何疑问，请发送电邮至 info@qmisit.com.hk  与本公司联络。",
                    "text3num": null,
                    "text2num": null
                },
                {
                    "bigtitle": "个人信息收集声明",
                    "titlenum": null,
                    "textnum": null,
                    "text3": null,
                    "text2": null,
                    "text": null,
                    "title": null,
                    "text3num": null,
                    "text2num": null
                },
                {
                    "bigtitle": null,
                    "titlenum": null,
                    "textnum": null,
                    "text3": null,
                    "text2": null,
                    "text": null,
                    "title": "您提供的信息将用于处理您的咨询。我们不会把您的信息及身份向任何第三方透露。您有权要求查阅或修改在我们记录里与您有关的信息；在任何情况下，如果您希望查阅或修改你在我们记录里的个人信息，请发送电子邮件至info@qmisit.com.hk。",
                    "text3num": null,
                    "text2num": null
                }
                
            ]
          }
      },
      mounted() {
        this.getdate()
        window.scrollTo({
          top: 0,
          behavior: 'smooth' // 平滑滚动效果
        });
        console.log(this.onlinestate);
        this.getdata();
        var scrolltop = '';
        window.onscroll = function(e) {
				this.isscrollnum = JSON.parse(JSON.stringify(e))
				let island = 1.5
				//判断scroll是否向上还是向下 比较当前的值是否比之前值的大小
				if (scrolltop > this.isscroll) { //向下
					island = 1.5
					// if (scrolltop < 857) {
					document.getElementById('wrap_hand').className = 'wrap_hand'
					// }
				} else { //上
					island = -1.5
					// if (scrolltop < 50) {
					// 	document.getElementById('wrap_hand').className = 'wrap_hand wrap_hand_animations'
					// } else {
					document.getElementById('wrap_hand').className = 'wrap_hand wrap_hand_animation'
					// }
				}
				if (scrolltop < 50) {
					// document.getElementById('mytitle_img').className = 'mytitle_img'
				} else if (scrolltop > 857) {
					// document.getElementById('mytitle_img').className = ' ';
				}
				let imgList = document.getElementsByClassName("warp_abc");
				let iimg_fa = document.getElementsByClassName('heig_div');
				// let isbili = (document.documentElement.scrollTop/document.documentElement.scrollHeight).toFixed(2);
				this.isscroll = scrolltop;
				scrolltop = document.documentElement.scrollTop;
				// for (let key in imgList) {
				// 	if (imgList[key].style) {
				// 		let start = imgList[key].style.transform.split(',')[1] || '0px';
				// 		start = start.split('px')[0] * 1;
				// 		let strheight = (imgList[key].clientHeight < (iimg_fa[key].clientHeight - (start - island))) ?
				// 			start : start - island;
				// 		strheight = isNaN(strheight) ? 0 : strheight > 0 ? 0 : strheight;
				// 		let str3d = 'translate3d(0px,' + strheight + 'px, 0px)';
				// 		if (key == 0 && (iimg_fa[key].clientHeight + document.getElementById('mytitle_img')
				// 				.clientHeight > scrolltop)) {
				// 			imgList[key].style.transform = str3d;
				// 		}
				// 		if ((key == 1 || key == 2) && (iimg_fa[key].clientHeight * 2 + document.getElementById(
				// 				'mytitle_img').clientHeight > scrolltop) &&
				// 			(iimg_fa[key].clientHeight < scrolltop)) {
				// 			imgList[key].style.transform = str3d;
				// 		}
				// 		if ((key == 4 || key == 3) && (iimg_fa[key].clientHeight * 3 + document.getElementById(
				// 				'mytitle_img').clientHeight > scrolltop) &&
				// 			document.getElementById('mytitle_img').clientHeight < scrolltop) {
				// 			imgList[key].style.transform = str3d;
				// 		}
				// 	}
				// }
				
			}
      },
      methods: {
        getdate(){
          this.$ajax({
            method:'post',
            url:this.$api.getPrivacyPolicyPage,
            data:{
              page_name:"privacyPolicy"
            }
          }).then(res=>{
            if(res.data.qmiscode === 1000){
              this.params = res.data
              this.privacyPolicyList = res.data.privacyPolicy

            }else{
              this.$message.warning(res.data.qmismsg)
            }
          })
        },
        menuPage(){
          console.log("菜單111111");
          this.menuFlag = !this.menuFlag
          if(this.menuFlag){
            this.stopMove()
          }else{
            this.Move()
          }
        },
        stopMove(){
          // let m = function(e){e.preventDefault();};
          // document.body.style.overflow='hidden';
          // document.addEventListener("touchmove",m,{ passive:false });//禁止页面滑动
        },
        //开启页面滚动
        Move(){
          this.menuFlag = false
          let m =function(e){e.preventDefault();};
          document.body.style.overflow='';//出现滚动条
          document.removeEventListener("touchmove",m,{ passive:true });
        },
        backClick(){
            this.$router.push({ path: '/', query: { id: 0 }  });
        },
        skipClick(val){
          if (val == 1) {
            this.$router.push({
              path: '/', 
						  query: { id: 0 } 
            });
          }
          if (val == 2) {
            this.$router.push({
              path: '/aboutUs', 
              query: { id: 2 } 
            });
          }
          if (val == 3) {
            this.$router.push({
              path: '/', 
              query: { id: 3 } 
            });
          }
          if (val == 4) {
            this.$router.push({
              path: '/', 
              query: { id: 4 } 
            });
          }
          if (val == 5) {
            this.$router.push({
              path: '/aboutUs', 
              query: { id: 5 } 
            });
          }
          if (val == 6) {
            this.$router.push({
              // path: '/contactUs', 
              path: '/', 
              query: { id: 6 } 
            });
          }
        },
        getdata(){
          // clearTimeout()
          setTimeout(() => {
            this.pageFlag = false
          }, 500)
        }
      }
      
  }
</script>

<style lang='less' scoped>
* {
		box-sizing: border-box;
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		font-family: arial;
	}
	body {
		padding: 0;
		margin: 0;
		text-align: center;
		font-family: arial;
	}
	section {
		// height: 300px;
		// padding-top: 100px;
		float: left;
		width: 50%;
		position: relative;
		/*==============++++================*/
	}
	.menu {
		height: 28px;
		width: 28px;
		position: relative;
		margin: auto;
		// padding-top: 20px;
		border: 5px solid transparent;
		-moz-border-radius: 100%;
		-webkit-border-radius: 100%;
		border-radius: 100%;
		-moz-transition: 0.3s;
		-o-transition: 0.3s;
		-webkit-transition: 0.3s;
		transition: 0.3s;
		cursor: pointer;
	}
	.bar {
		height: 3px;
		width: 28px;
		display: block;
		// margin: 10px auto;
		position: relative;
		background-color: #000;
		-moz-border-radius: 10px;
		-webkit-border-radius: 10px;
		border-radius: 10px;
		-moz-transition: 0.4s;
		-o-transition: 0.4s;
		-webkit-transition: 0.4s;
		transition: 0.4s;
	}
	.model-1 .bar {
		position: absolute;
	}
	.model-1 .bar:nth-of-type(1) {
		// top: 15px;
		-moz-transition: top 0.3s ease 0.3s, -moz-transform 0.3s ease-out 0.1s;
		-o-transition: top 0.3s ease 0.3s, -o-transform 0.3s ease-out 0.1s;
		-webkit-transition: top 0.3s ease, -webkit-transform 0.3s ease-out;
		-webkit-transition-delay: 0.3s, 0.1s;
		transition: top 0.3s ease 0.3s, transform 0.3s ease-out 0.1s;
		-moz-animation: mrotr 2s cubic-bezier(0.5, 0.2, 0.2, 1.01);
		-webkit-animation: mrotr 2s cubic-bezier(0.5, 0.2, 0.2, 1.01);
		animation: mrotr 2s cubic-bezier(0.5, 0.2, 0.2, 1.01);
	}
	.model-1 .bar:nth-of-type(2) {
		top: 10px;
		-moz-transition: 0.3s ease 0.3s;
		-o-transition: 0.3s ease 0.3s;
		-webkit-transition: 0.3s ease;
		-webkit-transition-delay: 0.3s;
		transition: 0.3s ease 0.3s;
		-moz-animation: fade 2s cubic-bezier(0.5, 0.2, 0.2, 1.01);
		-webkit-animation: fade 2s cubic-bezier(0.5, 0.2, 0.2, 1.01);
		animation: fade 2s cubic-bezier(0.5, 0.2, 0.2, 1.01);
	}
	.model-1 .bar:nth-of-type(3) {
		top: 20px;
		-moz-transition: top 0.3s ease 0.3s, -moz-transform 0.3s ease-out 0.1s;
		-o-transition: top 0.3s ease 0.3s, -o-transform 0.3s ease-out 0.1s;
		-webkit-transition: top 0.3s ease, -webkit-transform 0.3s ease-out;
		-webkit-transition-delay: 0.3s, 0.1s;
		transition: top 0.3s ease 0.3s, transform 0.3s ease-out 0.1s;
		-moz-animation: mrotl 2s cubic-bezier(0.5, 0.2, 0.2, 1.01);
		-webkit-animation: mrotl 2s cubic-bezier(0.5, 0.2, 0.2, 1.01);
		animation: mrotl 2s cubic-bezier(0.5, 0.2, 0.2, 1.01);
	}
	.model-1 .menu_hover .bar:nth-of-type(1) {
		top: 10px;
		-moz-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		-webkit-transform: rotate(45deg);
		transform: rotate(45deg);
		-moz-transition: top 0.3s ease 0.1s, -moz-transform 0.3s ease-out 0.5s;
		-o-transition: top 0.3s ease 0.1s, -o-transform 0.3s ease-out 0.5s;
		-webkit-transition: top 0.3s ease, -webkit-transform 0.3s ease-out;
		-webkit-transition-delay: 0.1s, 0.5s;
		transition: top 0.3s ease 0.1s, transform 0.3s ease-out 0.5s;
	}
	.model-1 .menu_hover .bar:nth-of-type(2) {
		opacity: 0;
	}
	.model-1 .menu_hover .bar:nth-of-type(3) {
		top: 10px;
		-moz-transform: rotate(-45deg);
		-ms-transform: rotate(-45deg);
		-webkit-transform: rotate(-45deg);
		transform: rotate(-45deg);
		-moz-transition: top 0.3s ease 0.1s, -moz-transform 0.3s ease-out 0.5s;
		-o-transition: top 0.3s ease 0.1s, -o-transform 0.3s ease-out 0.5s;
		-webkit-transition: top 0.3s ease, -webkit-transform 0.3s ease-out;
		-webkit-transition-delay: 0.1s, 0.5s;
		transition: top 0.3s ease 0.1s, transform 0.3s ease-out 0.5s;
	}

  .menu_div{
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.6);
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		display: flex;
		justify-content: center;
		align-items: center;
	}

.mc_cla{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  background-color: #ffffff;
}
@media (min-width: 767px) and (max-width: 1007.98px) {
    .xieyi_div{
     width: 90% !important;
   }
}
@media only screen and (max-width: 767px) {
    .xieyi_div{
       width: 90% !important;
     }
}
.xieyi_div{
  width: 60%;
  margin: auto;
  font-size: 16px;
  color: #333333;
}
.xieyi_div pre{
 border: none;
 padding: 0;
 font-size: 16px;
 line-height: 1.5;
 word-break:normal;
}
.look_g{
  font-size: 18px;
  margin-bottom: 1rem;
  color: #09666e;
  font-weight: 600;
  margin-top: 2rem;
}
.look_g div{
  display: inline-block;
  width: 1px;
  height: 15px;
  position: relative;
  top: 0.1rem;
  margin-right: 0.5rem;
  border-left: 3px solid #09666e;
}
.fonts{
  white-space: pre-wrap;
  font-family: "\5FAE\8EDF\6B63\9ED1\9AD4, sans-serif";
  margin-bottom: 0.2rem;
}
.heard_left img {
    width: 17rem;
}   
.wrap_hand {
    /* display: inline-block; */
    overflow: hidden;
    position: absolute;
    width: 100%;
    /* left: 4%; */
    padding: 10px 4%;
    z-index: 100;
    background: rgba(255, 255, 255, 0.7);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}
.wrap_hand_animation {
    position: fixed;
    top: 0;
    -webkit-animation-name: hand-out;
    -moz-animation-name: hand-out;
    -o-animation-name: hand-out;
    animation-name: hand-out;
    -webkit-animation-duration: .5s;
    -moz-animation-duration: .5s;
    -o-animation-duration: .5s;
    animation-duration: .5s;
    background: rgba(255, 255, 255, .7);
}
@keyframes hand-out {
    0% {
        -webkit-transform: translateY(-110px)
    }

    100% {
        -webkit-transform: translateY(0)
    }
}

@-webkit-keyframes hand-out {
    0% {
        -webkit-transform: translateY(-110px)
    }

    100% {
        -webkit-transform: translateY(0)
    }
}

@-moz-keyframes hand-out {
    0% {
        -webkit-transform: translateY(-110px)
    }

    100% {
        -webkit-transform: translateY(0)
    }
}

@-o-keyframes hand-out {
    0% {
        -webkit-transform: translateY(-110px)
    }

    100% {
        -webkit-transform: translateY(0)
    }
}

.wrap_hand_animation img {
    -webkit-transition: padding 0.3s linear;
    -moz-transition: padding 0.3s linear;
    -o-transition: padding 0.3s linear;
    transition: padding 0.3s linear;
}

@media screen and(max-width:980px){
 
  .menu_class{
    font-size: 4.5rem !important;
  }
  .heard_left img {
    width: 63rem !important;
  }
 

}
.phone_class .look_g{
  margin-bottom: 3rem !important;
  margin-top: 8rem !important;
}
  
.ipad_class .heard_left img{
  width: 35rem;
}
.back_hover:hover{
  color: #40bfbf;
}
.menu_class{
  font-size: 26px;
}
.ipad_class{
		.heard_left img{
			width: 35rem !important;
		}
		// .heard_right{
		// 	padding-top: 2rem;
		// }
		.menu_class{
			font-size: 26px !important;
		}
	}
</style>